<template>
  <div class="col-12" style="padding:20px;">
      <div v-if="!userId" class="text-center">
          Job Details become available once the user has been saved.
      </div>
      <div v-if="employeeJobDetails">
        <div class="card-inner">
          <a-form-model ref="user" :model="employeeJobDetails">
          <div class="row">
              <div class="col-12">
                  <button v-on:click="saveJobDetails()"  class="btn btn-success btn-sm float-right">
                      <em class="icon ni ni-save-fill mr-2"></em>Save
                  </button>
              </div>
          </div>
          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Location</label>
            </div>
            <div class="col-4">
               <a-input class="invisibox" v-model="employeeJobDetails.location" placeholder="John Smith" />
            </div>
          </div>                   

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Department</label>
            </div>
            <div class="col-4">
              <a-select class="invisibox" v-model="employeeJobDetails.departmentId" placeholder="Select Department">
                  <a-select-option key="1">
                    Test
                  </a-select-option>
                </a-select>
            </div>
          </div>     
                                        
          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Job Role</label>
            </div>
            <div class="col-4">
              <a-input class="invisibox" v-model="employeeJobDetails.jobRole" placeholder="Engineer" />
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Reports To</label>
            </div>
            <div class="col-4">
              <a-select class="invisibox" show-search v-model="employeeJobDetails.reportsToId" option-filter-prop="children" :filter-option="filterOption" placeholder="Select Supervisor">
                  <a-select-option v-for="u in users" :key="u.id">
                      {{ u.firstName + ' ' + u.lastName}}
                  </a-select-option>
              </a-select>
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Start Date</label>
            </div>
            <div class="col-4">
              <a-date-picker class="invisibox" format="DD/MM/yyyy" v-model="employeeJobDetails.startDate" :class="{'is-error' : $v.employeeJobDetails.startDate.$invalid }" placeholder="Start Date" />
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">NI / SSN Number</label>
            </div>
            <div class="col-4">
              <a-input class="invisibox" v-model="employeeJobDetails.niNumberOrSSN" placeholder="NI / SSN Number" />
            </div>
          </div> -->

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Holiday Year</label>
            </div>
            <div class="col-4">
              <a-select class="invisibox" v-model="employeeJobDetails.holidayYearId" :class="{'is-error' : $v.employeeJobDetails.holidayYearId.$invalid }" placeholder="Select Holiday Year">
                  <a-select-option v-for="h in holidayYears" :key="h.id">
                      {{ h.name }}
                  </a-select-option>
              </a-select>
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Work Pattern</label>
            </div>
            <div class="col-4">
              <a-select class="invisibox" v-model="employeeJobDetails.workPatternId" :class="{'is-error' : $v.employeeJobDetails.workPatternId.$invalid }" placeholder="Select Work Pattern">
                  <a-select-option v-for="w in workPatterns" :key="w.id">
                      {{ w.text }}
                  </a-select-option>
              </a-select>
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Salary Period</label>
            </div>
            <div class="col-4">
               <a-input class="invisibox" v-model="employeeJobDetails.salaryPeriodId" placeholder="John Smith" />
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Salary Amount</label>
            </div>
            <div class="col-4">
              <a-input class="invisibox" v-model="employeeJobDetails.salaryAmount" placeholder="John Smith" />
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Salary Currency</label>
            </div>
            <div class="col-4">
              <a-input class="invisibox" v-model="employeeJobDetails.salaryCurrencyId" placeholder="John Smith" />
            </div>
          </div>
                                                                       
        </a-form-model>
        <button v-on:click="saveJobDetails()"  class="btn btn-success btn-sm float-right">
          <em class="icon ni ni-save-fill mr-2"></em>Save
        </button>
      </div>
  </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    validations: {
      employeeJobDetails: {
        startDate: {
          required
        },
        holidayYearId: {
          required
        },
        workPatternId: {
          required
        }
      }
    },
    data () {
        return {
            employeeJobDetails: null,
            workPatterns: null,
            holidayYears: null,
            users: null,
            filterOption: '',
        }
    },
    props: {
    userId: {
      type: String
    },
    default: null
    },
    created () {
        this.getEmployeeJobDetails()
        this.getLists()
    },
    watch: {
      userId: function() { // watch for change
         this.getEmployeeJobDetails()
      },
    },
    methods: {
         submitForm(formName) {
            this.$refs[formName].validate(valid => {
            if (valid) {
            this.saveJobDetails()
            } else {
            return false
            }
        })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        saveJobDetails () {
          this.employeeJobDetails.userId = this.userId
          if (this.userId != null) {
            if (this.$v.employeeJobDetails.$invalid) {
              this.$message.error('Please check for any form errors.')
            } else {
              this.$http.post('/users/Update_Employee_Job_Details/', this.employeeJobDetails)
              .then(() => {
                this.$message.success('Details Updated')
              })
              .catch(() => {
                this.$message.error('There has been an error')
              }) 
            }
          } else {
            if (this.$v.employeeJobDetails.$invalid) {
              this.$message.error('Please check for any form errors.')
            } else {
              this.$http.post('/users/Add_Employee_Job_Details/', this.employeeJobDetails)
              .then(() => {
                this.$message.success('Details Added')
              })
              .catch(() => {
                this.$message.error('There has been an error')
              })
            }
          }      
        },
        getEmployeeJobDetails () {
            this.$http.get('/users/get_EmployeeJobDetails/' + this.userId)
            .then((response) => {
              this.employeeJobDetails = response.data
            })
            .catch(() => {
              this.$message.error('There has been an error')
            })
        },
        getLists(){
          this.$http.get('/lists/Get_List/workPatterns')
          .then((response) => {
              this.workPatterns = response.data
          })
          .catch(() => {
              this.$message.error('There has been an error')
          })
          this.$http.get('/settings/Get_Holiday_Years')
          .then((response) => {
              this.holidayYears = response.data
          })
          .catch(() => {
              this.$message.error('There has been an error')
          })
          this.$http.get('/users/Get_Users')
            .then((response) => {
                this.users = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style scoped>
.form-label {
  margin-top: 9px
}
</style>