<template>
  <div class="col-12" style="padding:20px;">
      <div v-if="!userId" class="text-center" >
          Job Details become available once the user has been saved.
      </div>
      <div v-if="annualLeave">
          <div class="row">
              <div class="col-12">
                <button class="btn btn-sm btn-success float-right" html-type="submit" @click="submitForm('annualLeave')">
                    <em class="icon ni ni-save-fill mr-2"/> Save
                </button>
              </div>
          </div>
      <a-form-model ref="annualLeave" :model="annualLeave" :label-col="labelCol" :wrapper-col="wrapperCol">
            <div class="row g-3 align-center">
                <div class="col-lg-5">
                    <div class="form-group">
                        <label class="form-label float-right" for="durationMeasurementId">Annual Leave Measurement</label>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <div class="form-control-wrap">
                        <a-select v-model="annualLeave.durationMeasurementId" :class="{'is-error' : $v.annualLeave.durationMeasurementId.$invalid }" type="text" class="invisibox" id="durationMeasurementId" placeholder="Select Annual Leave Measurement">
                            <a-select-option v-for="a in durationMeasurements" :key="a.id">
                                {{ a.text }}
                            </a-select-option>
                        </a-select>
                        </div>
                    </div>
                </div>
            </div>
          
          <!-- <a-form-model-item
            prop="durationMeasurementId"
            ref="durationMeasurementId"
            :rules="{
              required: false,
              message: 'Please enter the users full name',
              trigger: 'blur',
            }"
            label="Location">
            <a-input v-model="annualLeave.durationMeasurementId" placeholder="John Smith" />
        </a-form-model-item>                             -->
                  
          <div class="row g-3 align-center">
                <div class="col-lg-5">
                    <div class="form-group">
                        <label class="form-label float-right" for="contractedHours">Contracted Hours</label>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <div class="form-control-wrap">
                        <a-input placeholder="-" v-model="annualLeave.contractedHours" type="text" class="invisibox" id="contractedHours"/>
                        </div>
                    </div>
                </div>
          </div>                                     
          <div class="row g-3 align-center">
                <div class="col-lg-5">
                    <div class="form-group">
                        <label class="form-label float-right" for="fullTimeHours">Full-Time Hours</label>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <div class="form-control-wrap">
                        <a-input placeholder="-" v-model="annualLeave.fullTimeHours" type="text" class="invisibox" id="fullTimeHours"/>
                        </div>
                    </div>
                </div>
          </div>                              
          <div class="row g-3 align-center">
                <div class="col-lg-5">
                    <div class="form-group">
                        <label class="form-label float-right" for="fullTimeEntitlement">Full-Time Entitlement</label>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <div class="form-control-wrap">
                        <a-input placeholder="-" v-model="annualLeave.fullTimeEntitlement" type="text" class="invisibox" id="fullTimeEntitlement"/>
                        </div>
                    </div>
                </div>
          </div>                                   
          <div class="row g-3 align-center">
                <div class="col-lg-5">
                    <div class="form-group">
                        <label class="form-label float-right" for="entitlementIncludesPublicHolidays">Entitlement Includes Public Holidays</label>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <div class="form-control-wrap">
                        <a-checkbox placeholder="-" v-model="annualLeave.entitlementIncludesPublicHolidays" id="entitlementIncludesPublicHolidays"/>
                        </div>
                    </div>
                </div>
          </div>                                
          <div class="row g-3 align-center">
                <div class="col-lg-5">
                    <div class="form-group">
                        <label class="form-label float-right" for="allowExceedAllowance">Allow Exceed Allowance</label>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <div class="form-control-wrap">
                        <a-checkbox v-model="annualLeave.allowExceedAllowance" id="allowExceedAllowance"/>
                        </div>
                    </div>
                </div>
          </div>
        </a-form-model>
        <button class="btn btn-sm btn-success float-right" html-type="submit" @click="submitForm('annualLeave')">
          <em class="icon ni ni-save-fill mr-2"/> Save
        </button>
        <br>

        <hr class="preview-hr">

        <div class="row g-3 align-center">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
                <div class="col-12">
                    <a-button class="btn btn-dark float-right" @click="addHolidayException()">Add Holiday Exception</a-button>
                    <h6>Holiday Exceptions</h6>
                </div>
                <div class="col-12">
                    <vue-good-table v-if="holidayExceptions"
                        :columns="columns"
                        :rows="holidayExceptions"
                        styleClass="vgt-table condensed"
                    >
                    <template slot="table-row" slot-scope="props">
                        <div  v-if="props.column.field == 'calendarDateId'" >
                            {{$moment(props.row.calendarDateId).format("LL")}}
                        </div>
                        <div v-else-if="props.column.field == 'action'">
                            <a-dropdown>
                            <a><em class="icon ni ni-more-h" style="fontSize: 20px"></em></a>
                            <a-menu slot="overlay">
                                <a-menu-item style="color: red;" @click="deleteHolidayException(props)"><em class="icon ni ni-cross-circle"/> Delete</a-menu-item>
                            </a-menu>
                            </a-dropdown>
                        </div>
                    </template>
                    </vue-good-table>
                </div>
            </div>
        </div>

        <a-modal v-model="showHolidayException" title="Holiday Exception">
            <div class="row">
                <div class="col-12">
                    <label class="form-label">Is it a National Holiday or a Working Day?</label>
                    <a-radio-group v-model="isHoliday">
                        <a-radio :value="1">
                            National Holiday
                        </a-radio>
                        <a-radio :value="2">
                            Working Day
                        </a-radio>
                    </a-radio-group>
                </div>
            </div>
            <hr class="preview-hr">
            <div class="row" v-if="isHoliday === 1">
                <div class="col-12">
                    <label class="form-label">National Holiday</label>
                        <a-select style="width:100%;" v-model="holidayException.nationalHolidayId">
                            <a-select-option v-for="n in nationalHolidays" :key="n.nationalHolidayId" v-on:click="setNationalHoliday(n)">
                                {{ n.name }}
                            </a-select-option>
                        </a-select>
                </div>
            </div>
            <div class="row" v-if="isHoliday === 2">
                <div class="col-12">
                    <label class="form-label">Date</label>
                    <a-date-picker style="width:100%;" v-model="holidayException.date"/>
                </div>
            </div>

            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="saveHolidayException()"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>
  </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    validations: {
        annualLeave: {
            durationMeasurementId: {
                required
            },
            contractedHours: {
                required
            },
            fullTimeHours: {
                required
            },
            fullTimeEntitlement: {
                required
            }
        }
    },
    data () {
        return {
            showHolidayException: false,
            annualLeave: null,
            durationMeasurements: null,
            columns: [
                { label: 'Date', field: 'calendarDateId'},
                { label: 'National Holiday Name', field: 'nationalHolidayName'},
                { label: 'Actions', field: 'action', tdClass: 'text-center', thClass: 'text-center', width: 200 },
            ],
            holidayExceptions: null,
            holidayException: {},
            nationalHolidays: null,
            isHoliday: 1,
        }
    },
    props: {
        userId: String,
        default: null
    },
    created () {
        this.getAnnualLeave()
        this.getLists()
        this.getHolidayExceptions()
    },
    watch: {
      userId: function() { // watch for change
         this.getAnnualLeave()
      },
    },
    methods: {
         submitForm(formName) {
            this.$refs[formName].validate(valid => {
            if (valid) {
            this.saveAnnualLeaveAllowance()
            } else {
            return false
            }
        })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        saveAnnualLeaveAllowance () {
            this.annualLeave.userId = this.userId
          if (this.userId != null) {
            this.$http.post('/users/Update_Employee_AnnualLeave/', this.annualLeave)
            .then(() => {
              this.$message.success('Details Updated')
            })
            .catch(() => {
              this.$message.error('There has been an error')
            })
          } else {
            this.$http.post('/users/Add_Employee_AnnualLeave/', this.annualLeave)
            .then(() => {
              this.$message.success('Details Added')
            })
            .catch(() => {
              this.$message.error('There has been an error')
            })
          }
        },
        getAnnualLeave () {
            this.$http.get('/users/Get_Employee_AnnualLeave_Allowance/' + this.userId)
            .then((response) => {
              this.annualLeave = response.data
            })
            .catch(() => {
              this.$message.error('There has been an error')
            })
        },
        getLists() {
            this.$http.get('/lists/Get_List/durationMeasurements')
            .then((response) => {
                this.durationMeasurements = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/settings/Get_Tenant_EnabledNationalHolidays')
            .then((response) => {
                this.nationalHolidays = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getHolidayExceptions() {
            this.$http.get('/people/Get_Holiday_Exceptions/' + this.userId)
            .then((response) => {
                this.holidayExceptions = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        addHolidayException() {
            this.showHolidayException = true
        },
        setNationalHoliday(n){
            this.holidayException.calendarDateId = n.calendarDateId
        },
        saveHolidayException(){
            this.holidayException.userId = this.userId
            if (this.isHoliday === 1) {
                this.holidayException.isHoliday = true
            } else {
                this.holidayException.isHoliday = false
            }

            this.$http.post('/people/Add_Holiday_Exception', this.holidayException)
            .then((response) => {
                this.holidayExceptions = response.data
                this.getHolidayExceptions()
                this.showHolidayException = false
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        deleteHolidayException(props){
            this.$http.post('/people/Delete_Holiday_Exception', props.row)
            .then(() => {
                this.$message.success('Holiday Exception Deleted')
                this.getHolidayExceptions()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>